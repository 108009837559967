import one from './01.png'
import two from './02.png'
import three from './03.png'
import four from './04.png'
import five from './05.png'
import six from './06.png'
import seven from './07.png'
import eight from './08.png'
import nine from './09.png'
import ten from './10.png'
import eleven from './11.png'
import twelve from './12.png'

export default [
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
]
